import React from "react";

import { base, content, contentInner, image } from "./CaseStudy.module.scss";

import Button from "../components/Button";

import { combine, getClassNameModifiers } from "../utils/classNames";
import { htmlToPlainText, getSlug } from "../utils/misc";

const CaseStudy = props => {
  const {
    color,
    title,
    body,
    author,
    authorDescription,
    url,
    imageSrc,
    imageAlt,
    eyebrowLabel,
    className,
  } = props;

  const modifiers = getClassNameModifiers(props, ["color", "noButton"]);

  const splitTitle = () => {
    const titleParts = title.split(" ");
    const titleFirst = titleParts.slice(0, titleParts.length - 1);
    const titleLast  = titleParts[titleParts.length - 1];

    return [titleFirst.join(" "), titleLast];
  };

  return (
    <div className={combine(base, className)}>
      <div className={combine(content, modifiers)}>
        <div className={combine(contentInner, modifiers)}>
          {eyebrowLabel && <h4>{eyebrowLabel}</h4>}
          <h2>{splitTitle()[0]}&nbsp;{splitTitle()[1]}</h2>
          <p>{htmlToPlainText(body)}</p>
          <h3>{author}</h3>
          <h4>{authorDescription}</h4>
          <Button
            color={color === "navy" ? "cerise" : "navy"}
            href={`/case-studies/${getSlug(url)}`}
          >
            Read more
          </Button>
        </div>
      </div>
      <div className={image}>
        <img src={imageSrc} alt={imageAlt} />
      </div>
    </div>
  );
};

export default CaseStudy;
