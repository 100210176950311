import React from "react";

import { base } from "./CaseStudiesBanner.module.scss";

const CaseStudiesBanner = props => {
  return (
    <section className={base}>
      <div>
        <h1>Case studies</h1>
        <p>
          Explore our case studies to see how Brandcrush is used by brands and businesses around the
          world.
        </p>
      </div>
      <img src="/images/case-studies-banner.svg" alt="" />
    </section>
  );
};

export default CaseStudiesBanner;
