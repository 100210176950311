import React, { useState } from "react";

import Input from "./Input";

import { useDebounce } from "../utils/hooks";

const Search = props => {
  const [isActive, setIsActive] = useState(false);

  const { className, onSearch, placeholder } = props;

  const debouncedSearch = useDebounce(value => onSearch(value));

  const handleSubmitOrChange = (evt, value) => {
    evt.preventDefault();

    if (onSearch && typeof onSearch === "function") {
      debouncedSearch(value);
      setIsActive(value !== "");
    }
  };

  const handleClear = () => {
    if (onSearch && typeof onSearch === "function") {
      onSearch("");
      setIsActive(false);
    }
  };

  return (
    <Input
      className={className}
      icon={isActive ? "/images/close-icon.svg" : "/images/search.svg"}
      iconAlt={isActive ? "Clear" : "Search"}
      onSubmit={isActive ? handleClear : handleSubmitOrChange}
      onChange={handleSubmitOrChange}
      clearOnSubmit={isActive}
      placeholder={placeholder}
    />
  );
};

export default Search;
