import React, { useRef, useEffect } from "react";
import { navigate } from "gatsby";

import { base, image, extract } from "./CaseStudyCard.module.scss";

import { combine } from "../utils/classNames";
import { htmlToPlainText, getSlug } from "../utils/misc";

const CaseStudyCard = props => {
  const { className, imageSrc, imageAlt, heading, body, slug, activationType } = props;

  const headingEl = useRef(null);
  const paragraphEl = useRef(null);

  useEffect(() => {
    const lineHeight = parseInt(getComputedStyle(headingEl.current).lineHeight.replace("px", ""));
    const headingHeight = parseInt(headingEl.current.offsetHeight);

    if (headingHeight > 3 * lineHeight) {
      paragraphEl.current.style["-webkit-line-clamp"] = 2;
    } else if (headingHeight > 2 * lineHeight) {
      paragraphEl.current.style["-webkit-line-clamp"] = 3;
    } else if (headingHeight > lineHeight) {
      paragraphEl.current.style["-webkit-line-clamp"] = 4;
    } else {
      paragraphEl.current.style["-webkit-line-clamp"] = 5;
    }
  });

  const handleClick = () => {
    navigate(`/case-studies/${getSlug(slug)}`);
  };

  return (
    <div className={combine(base, className)} onClick={handleClick}>
      <img className={image} src={imageSrc} alt={imageAlt} />
      <div className={extract}>
        <h4>{activationType}</h4>
        <h3 ref={headingEl}>{heading}</h3>
        <p ref={paragraphEl} dangerouslySetInnerHTML={{ __html: htmlToPlainText(body) }}></p>
      </div>
    </div>
  );
};

export default CaseStudyCard;
