import React from "react";

import { base } from "./Filter.module.scss";

import Select from "./Select";

const Filter = props => {
  const { className, options, initialValue, onChange } = props;

  return (
    <Select
      className={className}
      options={options}
      initialValue={initialValue}
      onChange={onChange}
    />
  );
};

export default Filter;
