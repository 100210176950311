import React from "react";

import {
  filters,
  filter,
  searchInput,
  partner,
  brand,
  caseStudy,
  caseStudiesContainer,
  buttonContainer,
  socialsBanner,
} from "./case-studies.module.scss";

import Layout from "../layouts/Layout";
import Filter from "../components/Filter";
import Search from "../components/Search";
import Button from "../components/Button";
import CaseStudiesBanner from "../partials/CaseStudiesBanner";
import CaseStudy from "../partials/CaseStudy";
import CaseStudyCard from "../partials/CaseStudyCard";
import SocialsBanner from "../partials/SocialsBanner";

import { useClientFilter, useRegexSearch, useClientPagination } from "../utils/hooks";

const CaseStudiesPage = props => {
  const {
    pageContext: {
      brandCaseStudies,
      partnerCaseStudies,
      brandHighlightCaseStudy,
      partnerHighlightCaseStudy,
      activationTypes,
    },
  } = props;

  const NUMBER_CASE_STUDIES = 3;

  const [_brandFilter, setBrandActivationType, filteredBrandCaseStudies] = useClientFilter(
    brandCaseStudies,
    "activationType"
  );

  const [_partnerFilter, setPartnerActivationType, filteredPartnerCaseStudies] = useClientFilter(
    partnerCaseStudies,
    "activationType"
  );

  const [userType, setUserType, _data] = useClientFilter(null);

  const [setBrandQuery, queriedBrandCaseStudies, brandSearchActive] = useRegexSearch(
    filteredBrandCaseStudies,
    ["title", "subtitle", "content"]
  );

  const [setPartnerQuery, queriedPartnerCaseStudies, partnerSearchActive] = useRegexSearch(
    filteredPartnerCaseStudies,
    ["title", "subtitle", "content"]
  );

  const [brandResults, showMoreBrand, shouldShowMoreBrand] = useClientPagination(
    NUMBER_CASE_STUDIES,
    queriedBrandCaseStudies
  );

  const [partnerResults, showMorePartner, shouldShowMorePartner] = useClientPagination(
    NUMBER_CASE_STUDIES,
    queriedPartnerCaseStudies
  );

  const setActivationType = activationType => {
    setBrandActivationType(activationType);
    setPartnerActivationType(activationType);
  };

  const handleSearch = query => {
    setBrandQuery(query);
    setPartnerQuery(query);
  };

  return (
    <Layout
      pageTitle="Case Studies | Brandcrush"
      ogImage="/images/seo/case-studies.jpg"
      ogDescription="See how brands and businesses around the world are unlocking the power of retail media, activations and partnerships."
    >
      <CaseStudiesBanner />
      <section className={filters}>
        <p>Filter by:</p>
        <Filter
          className={filter}
          options={[
            { value: "", label: "All case studies" },
            { value: "partner", label: "Partner" },
            { value: "brand", label: "Brand" },
          ]}
          initialValue=""
          onChange={setUserType}
        />
        <Filter
          className={filter}
          options={[
            { value: "", label: "All activation types" },
            ...activationTypes.map(at => ({ value: at, label: at })),
          ]}
          initialValue=""
          onChange={setActivationType}
        />
        <Search
          className={searchInput}
          onSearch={handleSearch}
          placeholder="Search by keyword..."
        />
      </section>
      {userType !== "brand" && (
        <section className={partner}>
          <h2>Partner highlight</h2>
          <CaseStudy
            className={caseStudy}
            color="navy"
            title={partnerHighlightCaseStudy.featured.headline}
            body={partnerHighlightCaseStudy.featured.extract}
            author={partnerHighlightCaseStudy.featured.author}
            authorDescription={partnerHighlightCaseStudy.featured.authorRole}
            url={partnerHighlightCaseStudy.slug}
            imageSrc={partnerHighlightCaseStudy.featureImages[0].src}
            imageAlt={partnerHighlightCaseStudy.featureImages[0].alt}
            noButton
          />
          <div className={caseStudiesContainer}>
            {partnerResults &&
              partnerResults.map(
                (caseStudy, index) =>
                  caseStudy && (
                    <CaseStudyCard
                      key={index}
                      imageSrc={caseStudy.featureImages[0].src}
                      imageAlt={caseStudy.featureImages[0].alt}
                      heading={caseStudy.title}
                      body={caseStudy.content}
                      slug={caseStudy.slug}
                      activationType={caseStudy.activationType}
                    />
                  )
              )}
          </div>
          {shouldShowMorePartner && (
            <div className={buttonContainer}>
              <Button isSecondary color="navy" onClick={showMorePartner}>
                Load more Partner case studies
              </Button>
            </div>
          )}
        </section>
      )}
      {userType !== "partner" && (
        <section className={brand}>
          <h2>Brand highlight</h2>
          <CaseStudy
            className={caseStudy}
            color="cerise"
            title={brandHighlightCaseStudy.featured.headline}
            body={brandHighlightCaseStudy.featured.extract}
            author={brandHighlightCaseStudy.featured.author}
            authorDescription={brandHighlightCaseStudy.featured.authorRole}
            url={brandHighlightCaseStudy.slug}
            imageSrc={brandHighlightCaseStudy.featureImages[0].src}
            imageAlt={brandHighlightCaseStudy.featureImages[0].alt}
            noButton
          />
          <div className={caseStudiesContainer}>
            {brandResults &&
              brandResults.map(
                (caseStudy, index) =>
                  caseStudy && (
                    <CaseStudyCard
                      key={index}
                      imageSrc={caseStudy.featureImages[0].src}
                      imageAlt={caseStudy.featureImages[0].alt}
                      heading={caseStudy.title}
                      body={caseStudy.content}
                      slug={caseStudy.slug}
                      activationType={caseStudy.activationType}
                    />
                  )
              )}
          </div>
          {shouldShowMoreBrand && (
            <div className={buttonContainer}>
              <Button isSecondary color="navy" onClick={showMoreBrand}>
                Load more Brand case studies
              </Button>
            </div>
          )}
        </section>
      )}
      <SocialsBanner className={socialsBanner} />
    </Layout>
  );
};

export default CaseStudiesPage;
